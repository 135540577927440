import All from "../All";
import Profession from "../profession/Profession";
import Provider from "../provider/Provider";
import Speciality from "../speciality/Speciality";
import Topic from "../topic/Topic";

const TopnavMobile = (props) => {
    return (
        <>
            <div className="mb-5">
                <div className="accordion accordion-flush" id="accordionFlushExample">
                    <div className="accordion-item mt-3">
                        <h2 className="accordion-header" id="flush-headingOne">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne" id="speciality-tab">
                                By Speciality
                            </button>
                        </h2>
                        <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                            <div className="accordion-body"><Speciality /></div>
                        </div>
                    </div>
                    <div className="accordion-item mt-3">
                        <h2 className="accordion-header" id="flush-headingTwo">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo" id="topic-tab">
                                By Topic
                            </button>
                        </h2>
                        <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                            <div className="accordion-body"><Topic /></div>
                        </div>
                    </div>
                    <div className="accordion-item mt-3">
                        <h2 className="accordion-header" id="flush-headingThree">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree" id="profession-tab">
                                By Profession
                            </button>
                        </h2>
                        <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                            <div className="accordion-body"><Profession /></div>
                        </div>
                    </div>
                    <div className="accordion-item mt-3">
                        <h2 className="accordion-header" id="flush-headingFour">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour" id="provider-tab">
                                By Provider
                            </button>
                        </h2>
                        <div id="flush-collapseFour" className="accordion-collapse collapse" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">
                            <div className="accordion-body"><Provider /></div>
                        </div>
                    </div>
                </div>
            </div>
            <All search={props.search} />
        </>
    )
}

export default TopnavMobile;