import React from "react";
import All from "../All";
import Profession from "../profession/Profession";
import Provider from "../provider/Provider";
import Speciality from "../speciality/Speciality";
import Topic from "../topic/Topic";
function Topnav(props) {

  return (
    <>
      <ul className="nav nav-tabs nav-justified mt-5" id="myTab" role="tablist">
        <li className="nav-item" role="presentation">
          <button className="nav-link active" id="All-tab" data-bs-toggle="tab" data-bs-target="#all" type="button" role="tab" aria-controls="all" aria-selected="true">All</button>
        </li>
        <li className="nav-item" role="presentation">
          <button className="nav-link" id="speciality-tab" data-bs-toggle="tab" data-bs-target="#speciality" type="button" role="tab" aria-controls="speciality" aria-selected="false">Speciality</button>
        </li>
        <li className="nav-item" role="presentation">
          <button className="nav-link" id="topic-tab" data-bs-toggle="tab" data-bs-target="#topic" type="button" role="tab" aria-controls="topic" aria-selected="false">Topic</button>
        </li>
        <li className="nav-item" role="presentation">
          <button className="nav-link" id="profession-tab" data-bs-toggle="tab" data-bs-target="#profession" type="button" role="tab" aria-controls="profession" aria-selected="false">Profession</button>
        </li>
        <li className="nav-item" role="presentation">
          <button className="nav-link" id="provider-tab" data-bs-toggle="tab" data-bs-target="#provider" type="button" role="tab" aria-controls="provider" aria-selected="false">Provider</button>
        </li>
      </ul>
      <div className="tab-content" id="myTabContent">
        <div className="tab-pane fade show active" id="all" role="tabpanel" aria-labelledby="All-tab"><All search={props.search} /></div>
        <div className="tab-pane fade" id="speciality" role="tabpanel" aria-labelledby="speciality-tab"><Speciality /></div>
        <div className="tab-pane fade" id="topic" role="tabpanel" aria-labelledby="topic-tab"><Topic /></div>
        <div className="tab-pane fade" id="profession" role="tabpanel" aria-labelledby="profession-tab"><Profession /></div>
        <div className="tab-pane fade" id="provider" role="tabpanel" aria-labelledby="profession-tab"><Provider /></div>
      </div>
    </>
  );
}
export default Topnav;