import { Link } from "react-router-dom";

const ProfessionMobile = (props) => {
    return (
        <>
            <div className="accordion accordion-flush" id="accordionFlushExampleProfession">
                {props.alphaKeys.map((alpha, index) => (
                    <>
                        {props.product[alpha].length > 0 ? (
                            <>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id={`flush-headingOneProf${alpha + index}`}>
                                        <button className="accordion-button collapsed fw-bold mt-2" type="button" data-bs-toggle="collapse" data-bs-target={`#flush-collapseOneProf${alpha + index}`} aria-expanded="false" aria-controls={`flush-collapseOneProf${alpha + index}`} style={{color:"#005248"}}>
                                            {alpha}
                                        </button>
                                    </h2>
                                    <div id={`flush-collapseOneProf${alpha + index}`} className="accordion-collapse collapse" aria-labelledby={`flush-headingOneProf${alpha + index}`} data-bs-parent="#accordionFlushExampleProfession">
                                        <ul className="mt-4 mb-4">
                                            {props.product[alpha]?.map((item, index) => (
                                                <>
                                                    <li className="text-start mt-2">
                                                        <Link className="text-decoration-none text-dark" to={`/topic-details/${item.mpd_id}`}>
                                                            {item.parent}
                                                        </Link>
                                                    </li>
                                                </>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </>
                        ) : ""}
                    </>
                ))}
            </div>
        </>
    )
}

export default ProfessionMobile;