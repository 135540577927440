import React, { useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import loadingImg from "./Assets/gif/loading.gif"
import { LoadingContext } from './Layout/Components/loadingContext';

const Main = ()=>{
  const [loading,setLoading]=useState(false);

  return(
    <>
    {loading===true?(
    <div className='preloading'>
      <img src={loadingImg} alt="preloader"/>
    </div>
  ):""}
  <React.StrictMode>
    <LoadingContext.Provider value={{setLoading}}>
    <App />
    </LoadingContext.Provider>
  </React.StrictMode>
    </>
  )
}

const root = ReactDOM.createRoot(document.getElementById('root'));


root.render(
  <Main />
);


