import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { LoadingContext } from "../loadingContext";

function Providersidebar(props) {
  const [providerName, setProviderName] = useState();
  const [alphaKeys, setAlphaKeys] = useState([]);
  const { setLoading } = useContext(LoadingContext);

  const providerData = async () => {
    try {
      const data = await axios.get(
        `${process.env.REACT_APP_NODE_URL}/provider/getprovider`
      );
      setProviderName(data.data);
      setAlphaKeys(Object.keys(data.data));
    } catch (e) {
      console.log(e);
    }
  };
  const getProviderDetails = async (status, id) => {
    setLoading(true);
    props.setModalShow();
    if (id) {
      await axios.post(
        `${process.env.REACT_APP_NODE_URL}/providerfilter/getfilterprovider`,
        {
          mprd_id: id
        }
      )
        .then((res) => {
          props.setFilterValue(res.data);
        })
        .catch((err) => console.log(err))
    } else {
      props.setFilterValue("");
    }
    setLoading(false);
  }

  useEffect(() => {
    providerData();
  }, []);
  return (
    <>
      <div className="dropdown mb-3 text-start pt-5">
        <button
          className="btn dropdown-toggle border-0 p-0 fs-5 fw-bold"
          type="button"
          data-bs-toggle="dropdown"
          style={{"color":"#005248"}}
        >
          Provider
        </button>
        <ul className="dropdown-menu translate-0 border-0 position-relative mt-1 body-color">
          <li>
            <div className="form-check">
              <div className="col">
                <div className="mt-2">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="provider"
                    value=""
                    // id="flexCheckDefault"
                    onClick={(event) => getProviderDetails(event.target.checked, event.target.value)}
                  />
                  <label
                    className="form-check-label text-danger fw-bold"
                    for="flexCheckDefault"
                  >
                    Clear Filter
                  </label>
                </div>
                {alphaKeys.map((items, index) => (
                  <>
                    {providerName[items].length > 0 ? (
                      <>
                        {providerName[items]?.map((item, index) => (
                          <>
                          <div className="mt-2">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="provider"
                              // id="flexCheckDefault"
                              onClick={(event) => {getProviderDetails(event.target.checked, item.mprd_id);}}
                            />
                            <label
                              className="form-check-label"
                              for="flexCheckDefault"
                            >
                              {item.parent}
                            </label>
                            <br />
                            </div>
                          </>
                        ))}
                      </>
                    ) : (
                      ""
                    )}
                  </>
                ))}
              </div>
            </div>
          </li>
        </ul>
      </div>
    </>
  )

}
export default Providersidebar;