import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Layout from "./Layout/Components";
import About from "./Layout/Components/About";
import Dashboard from "./Layout/Components/Dashboard";
import Details from "./Layout/Components/Details";
import ProfessionDetails from "./Layout/Components/profession/ProfessionDetails";
import ProviderDetails from "./Layout/Components/provider/ProviderDetails";
import SpecialityDetails from "./Layout/Components/speciality/SpecialityDetails";
import TopicDetails from "./Layout/Components/topic/TopicDetails";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route path="" element={<Dashboard />} />
            <Route path="/about" element={<About/>}/>
            <Route path="/search-details/:id" element={<Details />} />
            <Route path="/speciality-details/:id" element={<SpecialityDetails/>}/>
            <Route path="/topic-details/:id" element={<TopicDetails/>}/>
            <Route path="/profession-details/:id" element={<ProfessionDetails/>}/>
            <Route path="/provider-details/:id" element={<ProviderDetails/>}/>
          </Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
