import React from "react";

function Footer() {
  return (
    <>

      <div className="Footer">
        <div className="row">
          <div className="col-md-4 col-sm-12 text-center mt-4">
            <h5 className="mb-3"><b>About Us</b></h5>
            <a href="/about" className="text-decoration-none text-white" target="_blank"><p>About L4Z</p></a>
            <a href="https://lookforzebras.com/contact/" target="_blank" rel="noreferrer" className="text-decoration-none text-white"><p>Contact Us</p></a>
            <a href="https://lookforzebras.com/employers/" target="_blank" rel="noreferrer" className="text-decoration-none text-white"><p>Want to Hire?</p></a>
          </div>
          <div className="col-md-4 col-sm-12 text-center mt-4">
            <h5 className="mb-3"><b>Career Resources Us</b></h5>
            <a href="https://lookforzebras.com/physician-jobs/" target="_blank" rel="noreferrer" className="text-decoration-none text-white"><p>Job Board</p></a>
            <a href="https://lookforzebras.com/50-nonclinical-careers/" target="_blank" rel="noreferrer" className="text-decoration-none text-white"><p>The Book</p></a>
            <a href="https://lookforzebras.com/stampede/" target="_blank" rel="noreferrer" className="text-decoration-none text-white"><p>Stampede</p></a>
          </div>
          <div className="col-md-4 col-sm-12 text-center mt-4">
          <h5 className="mb-3"><b>Blog</b></h5>
          <a href="https://lookforzebras.com/browse-posts/" target="_blank" rel="noreferrer" className="text-decoration-none text-white"><p>Browse Post</p></a>
          <a href="https://lookforzebras.com/archives/" target="_blank" rel="noreferrer" className="text-decoration-none text-white"><p>Archives</p></a>
          <a href="https://lookforzebras.com/blog-categories/" target="_blank" rel="noreferrer" className="text-decoration-none text-white"><p>Search Posts</p></a>
          <a href="https://lookforzebras.com/contribute/" target="_blank" rel="noreferrer" className="text-decoration-none text-white"><p>Contribute</p></a>
          </div>
        </div>

      </div>
    </>
  )
}
export default Footer;