import { Outlet } from "react-router-dom";
import Footer from "./Footer";
import Navbar from "./Navbar";

const Layout = () => {
  return (
    <>
      <Navbar />
      <div className="row">
        <div className="col-1 body-color"></div>
        <div className="col-md-10 col-sm-12 body-color">
          <Outlet />
        </div>
        <div className="col-1 body-color"></div>
      </div>
      <Footer />
    </>
  );
};

export default Layout;
