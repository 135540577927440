/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ButtonField from "../../../Components/button";
import { LoadingContext } from "../loadingContext";
import SpecialityMobile from "./specialityMobile";

function Speciality() {
  const [product, setProduct] = useState([]);
  const [alphaKeys, setAlphaKeys] = useState([]);
  const { setLoading } = useContext(LoadingContext);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const getSpeciality = async () => {
    try {
      setLoading(true);
      const data = await axios.get(
        `${process.env.REACT_APP_NODE_URL}/specialities/getspecialities`
      );
      setLoading(false);
      setProduct(data.data);
      setAlphaKeys(Object.keys(data.data));
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    window.addEventListener('resize', function (event) {
      setScreenWidth(window.innerWidth);
    }, true);
    getSpeciality();
  }, []);

  return (
    <div className="container ">
      {screenWidth >= 992 ? (
        <div className="row row-cols-3">
          {alphaKeys.map((items, index) => (
            <>
              {product[items].length > 0 ? (
                <>
                  <div className="col mt-4 border-column">
                    {/* <h3 className="mt-4 text-start table-head">{items}</h3> */}
                    <ButtonField
                      className="btn-reg ps-3 pe-3 pt-2 mt-4 pb-2 button-side"
                      value={items}
                    />

                    {product[items]?.map((item, index) => (
                      <>
                        <h5 className="text-start pt-2 table-head">
                          {item.parent}
                        </h5>
                        <ul>
                          {item.name.split(",").map((items, index) => (
                            <li className="text-start ms-5 pt-3 table-data ">
                              <Link
                                className="text-decoration-none text-dark"
                                to={`/speciality-details/${item.msd_id.split(",")[index]
                                  }`}
                              >
                                {items}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </>
                    ))}
                  </div>
                </>
              ) : (
                ""
              )}
            </>
          ))}
        </div>) : <SpecialityMobile product={product} alphaKeys={alphaKeys} />}
    </div>
  );
}
export default Speciality;
