import { Link } from "react-router-dom";

const SpecialityMobile = (props) => {
    return (
        <>
            <div className="accordion accordion-flush" id="accordionFlushExampleSpeciality">
                {props.alphaKeys.map((alpha) => (
                    <>
                        {props.product[alpha]?.map((item, index) => (
                            <>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id={`flush-headingOneSpe${alpha + index}`}>
                                        <button className="accordion-button collapsed fw-bold mt-2" type="button" data-bs-toggle="collapse" data-bs-target={`#flush-collapseOneSpe${alpha + index}`} aria-expanded="false" aria-controls={`flush-collapseOneSpe${alpha + index}`} style={{color:"#005248"}}>
                                            {item.parent}
                                        </button>
                                    </h2>
                                    <div id={`flush-collapseOneSpe${alpha + index}`} className="accordion-collapse collapse" aria-labelledby={`flush-headingOneSpe${alpha + index}`} data-bs-parent="#accordionFlushExampleSpeciality">
                                        <div className="accordion-body">
                                            <ul className="mt-3 mb-3">
                                                {item.name.split(",").map((items, int) => (
                                                    <li className="text-start mt-2">
                                                        <Link
                                                            className="text-decoration-none text-dark"
                                                            to={`/speciality-details/${item.msd_id.split(",")[int]
                                                                }`}
                                                        >
                                                            {items}
                                                        </Link>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </>
                        ))}
                    </>
                ))}
            </div>
        </>
    )
}

export default SpecialityMobile;