/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useParams } from "react-router-dom";
import ButtonField from "../../Components/button";
import ImageField from "../../Components/image";
function CardDetails(props) {
  const [product, setProduct] = useState([]);
  const [filterProduct, setFilterProduct] = useState([]);
  const [names, setName] = useState([]);
  const { id } = useParams();


  useEffect(() => {
  }, [props]);


  function PaginatedItems({ itemsPerPage, items }) {

    const [itemOffset, setItemOffset] = useState(0);

    const endOffset = itemOffset + itemsPerPage;
    const currentItems = items.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(items.length / itemsPerPage);
    const handlePageClick = (event) => {
      const newOffset = (event.selected * itemsPerPage) % items.length;
      setItemOffset(newOffset);
    };

    return (
      <>
        <Items currentItems={currentItems} />
        <ReactPaginate
          nextLabel="next>>"
          onPageChange={handlePageClick}
          pageRangeDisplayed={1}
          marginPagesDisplayed={1}
          pageCount={pageCount}
          previousLabel="<<previous"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="page-link"
          containerClassName="pagination justify-content-center"
          activeClassName="active"
          renderOnZeroPageCount={null}
        />
      </>
    );
  }
  function Items({ currentItems }) {
    const [readLessBtnList, setReadLessBtnList] = useState([]);
    const [refreshFlag, setRefreshFlag] = useState(false);
    const HandleReadmore = (id, Btnindex) => {
      document.getElementById(id).classList.remove("readmore-section");
      let temp = readLessBtnList;
      temp.push(Btnindex);
      setReadLessBtnList(temp);
      setRefreshFlag(!refreshFlag);
    };
    const HandleReadless = (id, Btnindex) => {
      document.getElementById(id).classList.add("readmore-section");
      let temp = readLessBtnList;
      temp.splice(readLessBtnList.indexOf(Btnindex), 1);
      setReadLessBtnList(temp);
      setRefreshFlag(!refreshFlag);
    };
    return (
      <>
        <h3 className="parent-head text-start border-bottom mt-5 mb-5 pb-3">{currentItems?.[0]?.spl_name || currentItems?.[0]?.topic_parent || currentItems?.[0]?.profession_parent || currentItems?.[0]?.provider_parent || currentItems?.[0]?.format_parent}</h3>
        {currentItems.length > 0 ? currentItems.map((item, index) => (
          <div
            key={index}
            className="card mb-3 mt-4 card-shadow"
            style={{ maxWidth: "1000px", minHeight: "20vh" }}
          >
            <div className="row g-0">
              <div className="col-md-6 col-sm-12">
                <div className="card-body">
                  <h4 className="card-title text-start mt-3 card-head">
                    <b>{item.course_name}</b>
                  </h4>
                  <section
                    className="readmore-section"
                    id={`readmore-section-id-${index}`}
                  >
                    <h6 className="card-text text-start lh-base card-details">
                      {item.short_desc}
                    </h6>

                    <h6 className="card-text text-start mt-3 lh-base card-details">
                      {item.long_desc}
                    </h6>
                  </section>
                  <div className="text-start">
                    {readLessBtnList.includes(index) ? (
                      <button
                        onClick={() =>
                          HandleReadless(
                            `readmore-section-id-${index}`,
                            index
                          )
                        }
                        className="btn p-0 btn-md border-0 shadow-none text-primary bg-transparent text-decoration-underline"
                      >
                        Fewer Details..
                      </button>
                    ) : (
                      <button
                        onClick={() =>
                          HandleReadmore(
                            `readmore-section-id-${index}`,
                            index
                          )
                        }
                        className="btn p-0 btn-md border-0 shadow-none text-primary bg-transparent text-decoration-underline"
                      >
                        More Details..
                      </button>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-12 mt-5 pt-5 card-mobile card-details">
                <h6 className="card-text text-start  card-details">
                  <b>Credit Hours:</b>
                </h6>

                <h6 className="text-start card-details">
                  {" "}
                  {item.credit_hours}
                </h6>
                <h6 className="card-text text-start mt-2  card-details">
                  <b>Cost:</b>
                </h6>
                <h6 className="text-start card-details">
                  {" "}
                  {item.course_cost}
                </h6>
                <h6 className="card-text text-start  card-details">
                  <b>CME Credits awarded by:</b>
                </h6>
                <h6 className="text-start card-details"> {item.award_by}</h6>
                <h6 className="card-text text-start  card-details">
                  <b>Format:</b>
                </h6>
                <h6 className="text-start card-details"> {item.format_parent}</h6>
                {/* <h6 className="card-text text-start  card-details">
                      <b>Material Last Updated:</b>
                    </h6>
                    <h6 className="text-start card-details">
                      {" "}
                      {item.updated !== null ? item.updated.split("T")[0] : "NA"}
                    </h6> */}
              </div>
              {/* image section */}
              <div className="col-md-3 col-sm-12">
                <ImageField
                  src={item.img_url1}
                  className="img-fluid rounded-start img-mobile-card mt-4 pt-4"
                  width="200px"
                  height="200px"
                  alt="..."
                />
                <a href={item.course_link} target="_blank" rel="noreferrer">
                  <ButtonField
                    className="btn-reg mt-5 ps-5 pe-5 pt-2 pb-2"
                    value="View Offer!"
                  />
                </a>
              </div>
            </div>
          </div>
        )) : (
          <h3>No Records Found</h3>)}
      </>
    );
  }
  return (
    <>
      <PaginatedItems
        itemsPerPage={4}
        items={
          props.filterValue.length > 0 ? props.filterValue : props.product ? props.product : ""
        }
      />
    </>
  )
}
export default CardDetails;
