import React, { useEffect, useState } from "react";
import ImageField from "../../Components/image";
import bg from "../../Assets/image/About2.png";
import ag from "../../Assets/image/About1.png";
import cg from "../../Assets/image/About3.png";
function About() {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener('resize', function (event) {
      setScreenWidth(window.innerWidth);
    }, true);
  }, [])

  return (
    <>
      <div
        className="container-fluid"
        style={{ minHeight: "100vh", maxWidth: "1200px" }}
      >
        <h2 className="mt-5 about-color">
          <b>Hello there.</b>
        </h2>
        <h5 className="text-center mt-5 mb-5">
          When it comes to your career in medicine, look for zebras, not for
          horses. Being a medical professional doesn’t mean you need to settle
          for adequate. Seek out the unique. Find what’s fitting. Achieve
          awesomely.
        </h5>
        <div className="row mt-5 pt-5">
          <div className="col-md-6 col-sm-12">
            <h2 className="about-color">
              <b>Mission</b>
            </h2>
            <h5 className="mt-5 text-start about-text">
              <b>To bring fulfillment to the lives of medical professionals.</b>
              Look for Zebras is not about abandoning medicine, dealing with
              burnout, or commiserating with frustrated clinicians.It’s here to
              make sure you have a purposeful and happy life throughout your
              medical career – from the very beginning.
            </h5>
          </div>
          <div className="col-md-6 col-sm-12">
            <ImageField
              src={ag}
              width="350px"
              height="250px"
              className="rounded float-start"
            />
          </div>
        </div>
        <div className="row mt-5 pt-5">
          {screenWidth >= 992 ? (
            <>
              <div className="col-md-6 col-sm-12">
                <ImageField
                  src={bg}
                  width="350px"
                  height="250px"
                  className="rounded float-start"
                />
              </div>
              <div className="col-md-6 col-sm-12">
                <h2 className="about-color">
                  <b>Vision</b>
                </h2>
                <h5 className="mt-5 text-start about-text">
                  <b>
                    {" "}
                    To be a respected leader in unique careers and opportunities for
                    medical professionals.
                  </b>
                  I want you to get more out of medicine – more satisfaction, more
                  meaning, and more financial gratification – and I hope resources
                  and services on this site assist you in getting there.
                </h5>
              </div>
            </>
          ) : (
            <>
              <div className="col-md-6 col-sm-12">
                <h2 className="about-color">
                  <b>Vision</b>
                </h2>
                <h5 className="mt-5 text-start about-text">
                  <b>
                    {" "}
                    To be a respected leader in unique careers and opportunities for
                    medical professionals.
                  </b>
                  I want you to get more out of medicine – more satisfaction, more
                  meaning, and more financial gratification – and I hope resources
                  and services on this site assist you in getting there.
                </h5>
              </div>
              <div className="col-md-6 col-sm-12">
                <ImageField
                  src={bg}
                  width="350px"
                  height="250px"
                  className="rounded float-start"
                />
              </div>
            </>
          )}

        </div>
        <div className="row mt-5 pt-5 mb-5">
          <div className="col-md-6 col-sm-12">
            <h2 className="about-color">
              <b>Working Smarter</b>
            </h2>
            <h5 className="mt-5 text-start about-text">
              <b>
                A portion of the profits generated from this site is dedicated
                toward endeavors that improve health while also helping medical
                professionals find their niches and explore unique medical
                careers.
              </b>
              These include medical humanitarian and global health
              organizations.
            </h5>
          </div>
          <div className="col-md-6 col-sm-12">
            <ImageField
              src={cg}
              width="350px"
              height="250px"
              className="rounded float-start"
            />
          </div>
        </div>
      </div>
    </>
  );
}
export default About;
