import React from "react";
import { Link, useNavigate } from "react-router-dom";
import ImageField from "../../Components/image";
import bg from "../../Assets/image/LFZ.svg";

function Navbar() {
  const navigate = useNavigate();
  const setSpeciality = () => {
    navigate("/");
    document.getElementById("speciality-tab").click();
  };
  const setTopic = () => {
    navigate("/");
    document.getElementById("topic-tab").click();
  };
  const setProfession = () => {
    navigate("/");
    document.getElementById("profession-tab").click();
  };
  const setProvider = () => {
    navigate("/");
    document.getElementById("provider-tab").click();
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-light navbar-pad fs-5">
      <a class="navbar-brand" href="https://lookforzebras.com">
        <ImageField
          src={bg}
          width="200px"
          height="80px"
          className={"ms-3"}
          style={{ cursor: "pointer" }}
        />
      </a>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNavDropdown"
        aria-controls="navbarNavDropdown"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarNavDropdown">
        <ul className="navbar-nav ms-auto">
          <li className="nav-item dropdown me-3">
            <Link
              className="nav-link dropdown-toggle"
              to="#"
              id="navbarDropdownMenuLink"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <b>Browse</b>
            </Link>
            <div
              className="dropdown-menu"
              aria-labelledby="navbarDropdownMenuLink"
            >
              <div className="container">
                <button
                  className="dropdown-item nav-link"
                  onClick={() => setSpeciality()}
                >
                  <b>By Speciality</b>
                </button>
                <button
                  className="dropdown-item nav-link"
                  onClick={() => setTopic()}
                >
                  <b>By Topic</b>
                </button>
                <button
                  className="dropdown-item nav-link"
                  onClick={() => setProfession()}
                >
                  <b>By Profession</b>
                </button>
                <button
                  className="dropdown-item nav-link"
                  onClick={() => setProvider()}
                >
                  <b>By Provider</b>
                </button>
              </div>
            </div>
          </li>
          {/* <li className="nav-item  ms-5">
                <Link className="nav-link" to="#">
                  <b>FAQ</b>
                </Link>
              </li> */}
          {/* <li className="nav-item dropdown ms-5">
                <Link
                  className="nav-link dropdown-toggle"
                  to="#"
                  id="navbarDropdownMenuLink"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Sample Questions
                </Link>
                <div
                  className="dropdown-menu"
                  aria-labelledby="navbarDropdownMenuLink"
                >
                  <Link className="dropdown-item" to="#">
                    Action
                  </Link>
                </div>
              </li> */}

          <li className="nav-item me-3">
            <Link className="nav-link" to="/about" target="_blank">
              <b>About L4Z</b>
            </Link>
          </li>
        </ul>
      </div>
    </nav>
    // </div>
    // </div>
  );
}

export default Navbar;
