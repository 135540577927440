const ImageField=({name,className,src,width,height,id,alt,onClick,style})=>
(
    <img
    name={name}
    className={className}
    style={style}
    src={src}
    width={width}
    height={height}
    id={id}
    alt={alt}
    onClick={()=>onClick()}
    >
    </img>

);
export default ImageField;