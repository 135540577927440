import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Formatsidebar from './Formatsidebar';
import Providersidebar from './Providersidebar';

const SidebarMobile = (props) => {
    const [modalShow, setModalShow] = React.useState(false);
    return (
        <>
            <Button className="btn-lg float-start mt-5" variant="primary" onClick={() => setModalShow(true)}>
                Filter
            </Button>
            <Modal
                show={modalShow}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton onHide={() => setModalShow(false)}></Modal.Header>
                < Modal.Body >
                    <Providersidebar setFilterValue={(data) => props.setFilterValue(data)} setModalShow={()=>setModalShow(false)}/>
                    <Formatsidebar setFilterValue={(data) => props.setFilterValue(data)} setModalShow={()=>setModalShow(false)}/>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default SidebarMobile;