/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ButtonField from "../../../Components/button";
import TopicMobile from "./topicMobile";

function Topic() {
  const [product, setProduct] = useState([]);
  const [alphaKeys, setAlphaKeys] = useState([]);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const getTopics = async () => {
    try {
      const data = await axios.get(
        `${process.env.REACT_APP_NODE_URL}/topics/gettopics`
      );
      setProduct(data.data);
      setAlphaKeys(Object.keys(data.data));
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    window.addEventListener('resize', function (event) {
      setScreenWidth(window.innerWidth);
    }, true);
    getTopics();
  }, []);

  return (
    <>
      <div className=" container">
      {screenWidth >= 992 ? (
        <div className="row row-cols-3">
          {alphaKeys.map((items, index) => (
            <>
              {product[items].length > 0 ? (
                <>
                  <div className="col mt-3 border-column">
                    {/* <h2 className="mt-4 table-head">{items}</h2> */}
                    <ButtonField
                      className="btn-reg  ps-3 pe-3 pt-2 mt-4 pb-2 mb-4 button-side"
                      value={items}
                    />
                    {product[items]?.map((item, index) => (
                      <>
                        <p>
                          <ul>

                            <li className="text-start table-data ">
                              <Link className="text-decoration-none text-dark" to={`/topic-details/${item.mtd_id}`}>
                                {item.parent}
                              </Link>
                            </li>
                          </ul>
                        </p>
                      </>
                    ))}
                  </div>
                </>
              ) : (
                ""
              )}
            </>
          ))}
        </div>):<TopicMobile product={product} alphaKeys={alphaKeys} />}
      </div>
    </>
  );
}

export default Topic;
