import React, { useEffect, useState } from "react";
import Topnav from "./topnav/Topnav";
import TopnavMobile from "./topnav/topnavMobile";
function Dashboard() {
  const [search, setSearch] = useState("");
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener('resize', function (event) {
      setScreenWidth(window.innerWidth);
    }, true);
  }, [])

  return (
    <>
      <div className="container-fluid" style={{ minHeight: "100vh" }}>
        {/* <div className="text-center">
          <p className="text-center pt-5">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book. It has survived not
            only five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged.
          </p>
        </div> */}
        <div className="search-mobile form-group mt-5 mb-5">
          <input
            className="form-control w-auto ms-auto"
            type="text"
            placeholder="Search Here"
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        {screenWidth >= 992 ?
          <Topnav search={search} />
          : <TopnavMobile search={search} />}
      </div>
    </>
  );
}

export default Dashboard;
