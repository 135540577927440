/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CardDetails from "../cardDetails";
import { LoadingContext } from "../loadingContext";
import Sidebar from "../filterSidebar.js/Sidebar";
function ProviderDetails() {
  const [filterValue, setFilterValue] = useState([]);
  const [Product, setProduct] = useState([]);
  const { id } = useParams();
  const { setLoading } = useContext(LoadingContext);

  const getProviderData = async () => {
    try {
      setLoading(true);
      const data = await axios.get(
        `${process.env.REACT_APP_NODE_URL}/providercourses/getprovidercourses/` +
        id
      );
      setLoading(false);
      setProduct(data.data);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getProviderData();
  }, [])

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-md-2 col-sm-12">
            <Sidebar setFilterValue={(data) => setFilterValue(data)} />
          </div>
          <div className="col-md-9 col-sm-12">
            <CardDetails filterValue={filterValue} product={Product} />
          </div>
          <div className="col-md-1 col-sm-12"></div>
        </div>
      </div>
    </>
  );
}

export default ProviderDetails;
