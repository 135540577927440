
import Providersidebar from "./Providersidebar";
import Formatsidebar from "./Formatsidebar";
import { useEffect, useState } from "react";
import SidebarMobile from "./sidebarMobile";

const Sidebar=(props)=> {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener('resize', function (event) {
      setScreenWidth(window.innerWidth);
    }, true);
  }, [])

  return (
    <>
      {screenWidth >= 992 ? (
        <div
          className="mt-5 sidebar"
          style={{ minHeight: "108vh" }}
        >
          <Providersidebar setFilterValue={(data) => props.setFilterValue(data)} />
          <Formatsidebar setFilterValue={(data) => props.setFilterValue(data)} />
          {/* <Typescredit />
        <Creditsidebar /> */}
        </div>
      ) : <SidebarMobile setFilterValue={(data) => props.setFilterValue(data)}/>}
    </>
  );
}

export default Sidebar;
