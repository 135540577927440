import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { LoadingContext } from "../loadingContext";

function Formatsidebar(props) {
  const [formatName, setFormatName] = useState();
  const { setLoading } = useContext(LoadingContext);

  const formatData = async () => {
    try {
      const data = await axios.get(
        `${process.env.REACT_APP_NODE_URL}/formats/getformats`
      );
      setFormatName(data.data);
    } catch (e) {
      console.log(e);
    }
  };

  const getFormatDetails = async (status, id) => {
    setLoading(true);
    props.setModalShow();
    if (id) {
      await axios.post(
        `${process.env.REACT_APP_NODE_URL}/formatfilter/getformatfilter`,
        {
          mfd_id: id
        }
      )
        .then((res) => {
          props.setFilterValue(res.data);
        })
        .catch((err) => console.log(err))
    } else {
      props.setFilterValue("");
    }
    setLoading(false);
  }

  useEffect(() => {
    formatData();
  }, []);
  return (
    <>
      <div className="dropdown mb-3 text-start pt-3">
        <button
          className="btn dropdown-toggle border-0 p-0 fs-5 fw-bold"
          type="button"
          data-bs-toggle="dropdown"
          style={{"color":"#005248"}}
        >
          Format
        </button>
        <ul className="dropdown-menu translate-0 border-0 position-relative mt-1 body-color">
          <li>
            <div className="col">
              <div className="form-check">
                <div className="mt-2">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="format"
                    value=""
                    // id="flexCheckDefault"
                    onClick={(event) => getFormatDetails(event.target.checked, event.target.value)}
                  />
                  <label
                    className="form-check-label text-danger fw-bold"
                    for="flexCheckDefault"
                  >
                    Clear Filter
                  </label>
                </div>
                {formatName?.map((item, index) => (
                  <>
                  <div className="mt-2">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="format"
                      id="flexCheckDefault"
                      onClick={(event) => getFormatDetails(event.target.checked, item.mfd_id)}
                    />
                    <label className="form-check-label" for="flexCheckDefault">
                      {item.parent}
                    </label>
                    <br />
                    </div>
                  </>
                ))}
              </div>
            </div>
          </li>
        </ul>
      </div>

    </>
  )
}
export default Formatsidebar;