/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ButtonField from "../../Components/button";
import ImageField from "../../Components/image";

function Details() {
  const { id } = useParams();
  const [save, setSave] = useState([]);
  const viewData = async () => {
    try {
      const data = await axios.get(
        `${process.env.REACT_APP_NODE_URL}/courses/getallcourses/` + id
      );
      setSave(data.data);
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    viewData();
  }, []);
  return (
    <>
      <div className="container-fluid" style={{ minHeight: "100vh" }}>
        <div className="row mt-5 box-shadow">
          <div className="col-md-3 col-sm-12 mt-4 mb-4">
            {save.map((item) => (
              <>
                <ImageField
                  src={item.img_url1}
                  className={"mb-5 mb-sm-0"}
                  width="250px"
                  height="250px"
                />
              </>
            ))}
          </div>
          <div className="col-md-7 col-sm-12 text-mobile mt-3 mb-4">
            {save.map((item) => (
              <>
                <h3 className="text-start ms-5 mb-3 details-head">
                  <b>{item.course_name}</b>
                </h3>
              </>
            ))}
            {save.map((item) =>
            (
              <>
                <h6 className="text-start ms-5 mb-3 details-page"><b>Cost:</b>{item.course_cost}</h6>

                <h6 className="text-start ms-5 mb-3 details-page"><b>Credit Hours:</b>{item.credit_hours}</h6>
                <h6 className="text-start ms-5 mb-3 details-page"><b>Credit Awarded By:</b>{item.award_by}</h6>

              </>

            ))}

          </div>
          <div className="col-md-2 col-sm-12">
            {
              save.map((item) => (
                <>
                  <a href={item.course_link} target="_blank" rel="noreferrer">
                    <ButtonField
                      className="btn-link ps-4 pe-4 pt-2 pb-2"
                      value="LINK TO REGISTER!"
                    />
                  </a>
                </>
              ))
            }
          </div>
        </div>
        <h2 className="text-start mt-5 details-head">
          <b>Details</b>
        </h2>
        <h4 className="text-start pt-3">
          <b>Activity Description / Statement of Need</b>
        </h4>
        {save.map((item) => (
          <>
            <p className="text-start pt-3 table-data">{item.long_desc}</p>
          </>
        ))}

        <h3 className="text-start pt-3 details-head">
          <b>Target Audience:</b>
        </h3>
        {save.map((item) => (
          <>
            <p className="text-start pt-3 table-data">{item.short_desc}</p>
          </>
        ))}

        {/* <Link to={"/dashboard"}>Back</Link> */}
      </div>
    </>
  );
}
export default Details;
