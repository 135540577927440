import { Link } from "react-router-dom";

const TopicMobile = (props) => {
    return (
        <>
            <div className="accordion accordion-flush" id="accordionFlushExampleTopic">
                {props.alphaKeys.map((alpha, index) => (
                    <>
                        {props.product[alpha].length > 0 ? (
                            <>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id={`flush-headingOneTop${alpha + index}`}>
                                        <button className="accordion-button collapsed fw-bold mt-2" type="button" data-bs-toggle="collapse" data-bs-target={`#flush-collapseOneTop${alpha + index}`} aria-expanded="false" aria-controls={`flush-collapseOneTop${alpha + index}`} style={{color:"#005248"}}>
                                            {alpha}
                                        </button>
                                    </h2>
                                    <div id={`flush-collapseOneTop${alpha + index}`} className="accordion-collapse collapse" aria-labelledby={`flush-headingOneTop${alpha + index}`} data-bs-parent="#accordionFlushExampleTopic">
                                        <div className="accordion-body">
                                            <ul className="mt-3 mb-3">
                                                {props.product[alpha]?.map((item, index) => (
                                                    <>
                                                        <li className="text-start mt-2">
                                                            <Link className="text-decoration-none text-dark" to={`/topic-details/${item.mtd_id}`}>
                                                                {item.parent}
                                                            </Link>
                                                        </li>
                                                    </>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </>
                        ) : (
                            ""
                        )}
                    </>
                ))}
            </div>
        </>
    )
}

export default TopicMobile;