/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useNavigate } from "react-router-dom";
import ButtonField from "../../Components/button";
function All(props) {
  const [product, setProduct] = useState([]);
  const navigate = useNavigate();
  const getData = async () => {
    try {
      const data = await axios.get(
        `${process.env.REACT_APP_NODE_URL}/courses/getcourses`
      );
      setProduct(data.data);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (props.search !== "") {
      setProduct(
        product.filter(
          (item) => item.course_name.includes(props.search) === true
        )
      );
    } else {
      getData();
    }
  }, [props]);

  const setButtonnav = (id) => {
    navigate("/search-details/" + id);
  };

  function PaginatedItems({ itemsPerPage, items }) {
    const [itemOffset, setItemOffset] = useState(0);

    const endOffset = itemOffset + itemsPerPage;
    const currentItems = items.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(items.length / itemsPerPage);

    const handlePageClick = (event) => {
      const newOffset = (event.selected * itemsPerPage) % items.length;
      setItemOffset(newOffset);
    };

    return (
      <>
        <Items currentItems={currentItems} />
        <ReactPaginate
          nextLabel="next>>"
          onPageChange={handlePageClick}
          pageRangeDisplayed={1}
          marginPagesDisplayed={1}
          pageCount={pageCount}
          previousLabel="<<previous"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="page-link"
          containerClassName="pagination justify-content-center"
          activeClassName="active"
          renderOnZeroPageCount={null}
        />
      </>
    );
  }
  //
  function Items({ currentItems }) {
    return (
      <>
        <div className="overflow-scroll">
          <table className="table table-margin">
            <thead className="table-data">
              <tr className="text-start">
                <th scope="col">Title</th>
                <th scope="col">Credit</th>
                <th scope="col">Cost</th>
                <th scope="col">Date</th>
              </tr>
            </thead>
            <tbody className="table-data">
              {currentItems !== undefined
                ? currentItems.map((item) => {
                  return (
                    <>
                      <tr className="text-start">
                        <td>
                          {item.course_name}
                          <p>
                            <ButtonField
                              className="btn-reg ps-3 pe-3 pt-2 pb-2 mt-3"
                              value="View More...."
                              onClick={() => setButtonnav(item.dcd_id)}
                            />
                          </p>
                        </td>
                        <td>
                          {item.credit_hours !== null ? (
                            <>{item.credit_hours}</>
                          ) : (
                            <p>NA</p>
                          )}
                        </td>
                        <td>
                          {item.course_cost !== null ? (
                            <>{item.course_cost}</>
                          ) : (
                            <p>NA</p>
                          )}
                        </td>
                        <td>{item.created.split("T")[0]}</td>
                      </tr>
                    </>
                  );
                })
                : null}
            </tbody>
          </table>
        </div>
      </>
    );
  }
  return (
    <>
      <PaginatedItems
        itemsPerPage={10}
        items={product ? product : "No record found"}
      />
    </>
  );
}
export default All;
